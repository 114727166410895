import { combineForms } from 'react-redux-form'

import { dhvFormStateDefault } from 'dhv-common'
import { brokerId } from './brokerId.reducer'
import { brokerEmail } from './brokerEmail.reducer'
import { disableBrokerFields } from './disableBrokerFields.reducer'

export const completeStepReducer = combineForms(
  {
    acceptedContact: false,
    acceptedPhone: false,
    acceptedEmail: false,
    acceptedMobile: false,
    acceptedSms: false,
    brokerId: brokerId,
    brokerEmail: brokerEmail,
    brokerEmail2: dhvFormStateDefault.completeStep.brokerEmail2,
    disableBrokerFields: disableBrokerFields,
    milesAndMore: dhvFormStateDefault.completeStep.milesAndMore,
  },
  'dhvForm.completeStep'
)
