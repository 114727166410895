import { Schema } from 'common/dots-schema'

export const HaftpflichtCalculateSchemaModel = {
  coverage: {
    type: String,
    allowedValues: ['eu', 'world'],
  },
  insuranceSum: {
    type: Number,
    allowedValues: [3000000, 5000000, 10000000],
  },
  deductible: {
    type: Number,
    allowedValues: [0],
  },
}

export const HaftpflichtCheckoutSchemaModel = {
  ...HaftpflichtCalculateSchemaModel,
  priceBrutto: {
    type: Number,
  },
  priceTax: {
    type: Number,
  },
  priceNetto: {
    type: Number,
  },
}

export const HaftpflichtCalculateSchema = new Schema(HaftpflichtCalculateSchemaModel)
export const HaftpflichtCheckoutSchema = new Schema(HaftpflichtCheckoutSchemaModel)
