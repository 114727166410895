import { SET_DISABLEBROKERFIELDS } from 'client/dhv-form/api/client/actions/set-disablebrokerfields.action'
import { dhvFormStateDefault } from 'dhv-common'

export function disableBrokerFields(
  state: boolean = dhvFormStateDefault.completeStep.disableBrokerFields || false,
  action: any
): boolean {
  if (action.type === SET_DISABLEBROKERFIELDS) {
    return true
  } else {
    return state
  }
}
