import React from 'react'

import './locked.css'

interface LockedProps {
  onCloseClick: () => void
}

export const Locked = (props: LockedProps) => {
  return (
    <div className="locked-positioner">
      <div className="locked-container">
        <div className="locked-left">
          <div className="locked-content">
            Aufgrund Ihrer Angaben ist die vollautomatische Ausstellung Ihrer Drohnenversicherung über unsere Homepage leider
            nicht möglich.
            <br />
            <br />
            Wir beraten aber gerne individuell. Kontaktieren Sie uns einfach per E-Mail über die Schaltfläche auf der rechten
            Seite oder direkt an luftfahrt@zurich.com Wir freuen uns auf Ihre Nachricht!
          </div>
        </div>
        <div className="locked-right">
          <a
            className="locked-button"
            href="mailto:luftfahrt@zurich.com?subject=Individuelle Anfrage zur Multicopter Versicherung"
          >
            <div className="locked-buttonContent">
              <span className="locked-buttonText">Individuelles Angebot anfordern</span>
            </div>
          </a>
        </div>
        <span
          className="icon icon--close_24 locked__close-icon"
          onClick={props.onCloseClick}
          data-testid="closeLockedScreen"
        ></span>
      </div>
    </div>
  )
}
