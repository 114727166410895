import { combineForms } from 'react-redux-form'
import { dhvFormStateDefault } from 'dhv-common'
import { directDebitReducer } from './direct-debit.reducer'

export const paymentStep = combineForms(
  {
    paymentType: dhvFormStateDefault.paymentStep.paymentType,
    directDebit: directDebitReducer,
    acceptedDirectDebit: dhvFormStateDefault.paymentStep.acceptedDirectDebit,
  },
  'dhvForm.paymentStep'
)
