import React from 'react'
import { Route, Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import { RateStep } from 'dhv-form-rate'
import { ContractStep } from 'dhv-form-contract'
import { PersonStep } from 'dhv-form-person'
import { PaymentStep } from 'dhv-form-payment'
import { DownloadStep } from 'dhv-form-download'
import { CompleteStep } from 'dhv-form-complete'

import { stepRedirect } from '../../api/client/containers/redirect.container'

import './dhv-form.css'
import { ThanksStep } from 'dhv-form-thanks'
import { SetStepAction, SetActiveStepAction } from 'dhv-form'
import { ActionCreator } from 'redux'
import { Steps } from 'client/dhv-form/api/client/dhv-form.state'

export const ROUTES = {
  TARIF_RECHNER: '/tarifrechner',
  VERTRAGS_GRUNDLAGEN: '/vertrags-grundlagen',
  ALLGEMEINE_ANGABEN: '/allgemeine-angaben',
  ZAHLUNG: '/zahlungsinformationen',
  VERTRAGS_UNTERLAGEN: '/vertragsunterlagen',
  VERTRAGS_ABSCHLUSS: '/vertragsabschluss',
  THANKS: '/danke',
}

interface DhvFormProps {
  steps: Steps
  finish: any
  location?: any
  setStep: ActionCreator<SetStepAction>
  setActiveStep: ActionCreator<SetActiveStepAction>
}

const RedirectToTarif = () => {
  return <Redirect to={ROUTES.TARIF_RECHNER} />
}

export class DhvForm extends React.Component<DhvFormProps, {}> {
  clickLink = (event: any, step: number) => {
    if (this.props.finish.isLoading || this.props.steps.locked) {
      event.preventDefault()
    } else if (step < this.props.steps.current) {
      this.props.setStep(step)
    } else {
      event.preventDefault()
    }
  }

  getLinkClassName = (index: number) => {
    const { steps } = this.props
    if (steps.locked) {
      return 'locked'
    }
    return `${steps.current >= index ? 'active' : ''} ${steps.active === index ? 'selected' : ''} ${
      steps.current >= index ? '' : 'deactivated'
    }`
  }

  isNavigationActive = (index: number) => {
    return !this.props.finish.isLoading || index === 0 || this.props.steps.current < index
  }

  render() {
    const { steps, children } = this.props
    // steps.current is maximum possible step to jump to
    // steps.active is actual step where user is

    const getLinkSpan = (index: number) => {
      return steps.current > index ? (
        <p>
          <span className={`${index !== 5 ? 'progress-item' : ''}`} />
          <span className="icon icon--check_24 circle" />
        </p>
      ) : (
        <p>
          <span className={`${index < 5 ? 'progress-item deactivated' : ''}`} />
          <span className="icon circle">{index + 1}</span>
        </p>
      )
    }

    return (
      <div>
        <Row className="clearfix">
          <Col sm={12} className="">
            <div className="pagination">
              <ul>
                {/* tslint:disable:max-line-length */}
                <li className={this.getLinkClassName(0)}>
                  <Link
                    to={ROUTES.TARIF_RECHNER}
                    onClick={(event) => this.clickLink(event, 0)}
                    className={this.getLinkClassName(0)}
                  >
                    {getLinkSpan(0)}
                    <p className="text">Tarifrechner</p>
                  </Link>
                </li>
                <li className={this.getLinkClassName(1)}>
                  <Link
                    to={ROUTES.VERTRAGS_GRUNDLAGEN}
                    onClick={(event) => this.clickLink(event, 1)}
                    className={this.getLinkClassName(1)}
                  >
                    {getLinkSpan(1)}
                    <p className="text">
                      Vertrags-
                      <br />
                      grundlagen
                    </p>
                  </Link>
                </li>
                <li className={this.getLinkClassName(2)}>
                  <Link
                    to={ROUTES.ALLGEMEINE_ANGABEN}
                    onClick={(event) => this.clickLink(event, 2)}
                    className={this.getLinkClassName(2)}
                  >
                    {getLinkSpan(2)}
                    <p className="text">
                      Allgemeine
                      <br />
                      Angaben
                    </p>
                  </Link>
                </li>
                <li className={this.getLinkClassName(3)}>
                  <Link to={ROUTES.ZAHLUNG} onClick={(event) => this.clickLink(event, 3)} className={this.getLinkClassName(3)}>
                    {getLinkSpan(3)}
                    <p className="text">
                      Zahlungs-
                      <br />
                      informationen
                    </p>
                  </Link>
                </li>
                <li className={this.getLinkClassName(4)}>
                  <Link
                    to={ROUTES.VERTRAGS_UNTERLAGEN}
                    onClick={(event) => this.clickLink(event, 4)}
                    className={this.getLinkClassName(4)}
                  >
                    {getLinkSpan(4)}
                    <p className="text">
                      Vertrags-
                      <br />
                      unterlagen
                    </p>
                  </Link>
                </li>
                <li className={this.getLinkClassName(5)}>
                  <Link
                    to={ROUTES.VERTRAGS_ABSCHLUSS}
                    onClick={(event) => this.clickLink(event, 5)}
                    className={this.getLinkClassName(5)}
                  >
                    {getLinkSpan(5)}
                    <p className="text">
                      Vertrags-
                      <br />
                      abschluss
                    </p>
                  </Link>
                </li>
                {/* tslint:enable:max-line-length */}
              </ul>
            </div>
          </Col>
          <Col sm={12}>{children}</Col>
        </Row>
        <Route exact path="/" component={RedirectToTarif} />
        <Route path={ROUTES.TARIF_RECHNER} component={RateStep} />
        <Route path={ROUTES.VERTRAGS_GRUNDLAGEN} component={stepRedirect(ContractStep, 1)} />
        <Route path={ROUTES.ALLGEMEINE_ANGABEN} component={stepRedirect(PersonStep, 2)} />
        <Route path={ROUTES.ZAHLUNG} component={stepRedirect(PaymentStep, 3)} />
        <Route path={ROUTES.VERTRAGS_UNTERLAGEN} component={stepRedirect(DownloadStep, 4)} />
        <Route path={ROUTES.VERTRAGS_ABSCHLUSS} component={stepRedirect(CompleteStep, 5)} />
        <Route path={ROUTES.THANKS} component={stepRedirect(ThanksStep, 6)} />
      </div>
    )
  }
}
