import moment from 'moment'

import React from 'react'
import { ActionCreator } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { Form, Control } from 'react-redux-form'

import { FieldSet, FieldSetContent, FormGroup, Radio, Text } from 'zur-ui-forms'
import { DatePicker } from 'zur-ui-datepicker'
import { SetStepAction } from 'dhv-form'
import { ContractStepState } from 'dhv-form-contract'

import { ContractStepSchema } from '../../api/client/state/contract-step.schema'
import { SetLockedAction } from '../../api/client/actions/set-locked.action'
import { SetUnlockedAction } from '../../api/client/actions/set-unlocked.action'
import { Locked } from './components/locked.component'

import './contract-step.css'
import { Select } from 'client/zur-ui-forms/ui/select/select.component'

interface ContractStepProps extends RouteComponentProps<{}> {
  scope: string
  business: boolean
  contractStep: ContractStepState
  setStep: ActionCreator<SetStepAction>
  setLocked: ActionCreator<SetLockedAction>
  setUnlocked: ActionCreator<SetUnlockedAction>
}

class ContractStep extends React.Component<ContractStepProps, {}> {
  onSubmit = () => {
    const { contractStep, scope, setStep, setLocked, history } = this.props
    if (contractStep.locked) {
      return
    }
    if (
      contractStep.preDamages === 'ja' ||
      contractStep.preExistingContracts === 'ja' ||
      (scope !== 'haftpflicht' && parseInt(contractStep.droneBuildYear, 10) < parseInt(moment().format('YYYY'), 10) - 2)
    ) {
      setLocked()
    } else {
      setStep(2)
      history.push('/allgemeine-angaben')
    }
    return false
  }

  onLockedClose = () => {
    this.props.setUnlocked()
  }

  showError = (field: any) => {
    return field.touched && !field.valid
  }

  getYearsArray = () => {
    const arr = []
    const x = 1999
    const y = parseInt(moment().format('YYYY'), 10)
    for (let i = y; i > x; i--) {
      arr.push(i)
    }
    return arr
  }

  render() {
    const { contractStep, business } = this.props
    const validators = ContractStepSchema.getValidators(contractStep, {
      context: contractStep,
      autoClean: true,
    })

    const baujahrChildren = this.getYearsArray().map((year, index) => (
      <option key={index} value={year}>
        {year}
      </option>
    ))
    baujahrChildren.splice(
      0,
      0,
      <option value="" key={-1}>
        --Bitte wählen--
      </option>
    )

    return (
      <div className="contractStep">
        {contractStep.locked && <Locked onCloseClick={this.onLockedClose} />}
        <Form model="dhvForm.contractStep" className={'form-horizontal insurance-form'} onSubmit={this.onSubmit}>
          <FieldSet title="Vertragsgrundlagen">
            <FieldSetContent>
              <FormGroup label="Versicherungsbeginn*" htmlFor="startDate" error={this.showError(contractStep.forms.startDate)}>
                <DatePicker
                  id="startDate"
                  disabled={contractStep.locked}
                  model=".startDate"
                  validators={validators.startDate}
                  messages={{
                    required: 'Bitte den Versicherungsbeginn angeben (maximal 1 Jahr in der Zukunft)',
                    custom: 'Bitte den Versicherungsbeginn angeben (maximal 1 Jahr in der Zukunft)',
                  }}
                  minDate={new Date()}
                  maxDate={moment().add(1, 'year').toDate()}
                />
              </FormGroup>
              <FieldSetContent>
                <p style={{ marginTop: '10px' }}>
                  Es handelt sich bei dieser Versicherung um einen Jahresvertrag. Der Versicherungsvertrag verlängert sich
                  stillschweigend von Jahr zu Jahr, wenn Sie nicht spätestens 3 Monate vor dem jeweiligen Ablauftermin kündigen.
                </p>
                <p>
                  Zum Zeitpunkt des gewünschten Versicherungsbeginns sollte die Versicherung der Drohne und/oder des Halterrisikos
                  nicht über einen anderen Vertrag abgedeckt sein
                </p>
              </FieldSetContent>
            </FieldSetContent>
          </FieldSet>
          <FieldSet>
            <FieldSetContent>
              <p>
                <strong>
                  Wir weisen darauf hin, dass Sie als Antragssteller verpflichtet sind, die folgenden Fragen vollständig und
                  wahrheitsgemäß zu beantworten, da wir die Angaben im Rahmen der Risikoprüfung benötigen. Zur Überprüfung und
                  Ergänzung ihrer Angaben kann ein Datenaustausch mit anderen Versicherern erforderlich werden.
                </strong>
              </p>
            </FieldSetContent>
          </FieldSet>
          <FieldSet>
            <FieldSetContent>
              <FormGroup
                label="Wurden Anträge zu einer Drohnenversicherung bereits abgelehnt oder wurde ein Drohnenversicherungsvertrag durch einen Vorversicherer gekündigt?"
                error={this.showError(contractStep.forms.preExistingContracts)}
              >
                <Control.radio
                  disabled={contractStep.locked}
                  model=".preExistingContracts"
                  value={contractStep.preExistingContracts}
                  name="preExistingContracts"
                  component={Radio}
                  errors={validators.preExistingContracts}
                  controlProps={{
                    options: [
                      { label: 'Ja', value: 'ja' },
                      { label: 'Nein', value: 'nein' },
                    ],
                    messages: {
                      required: 'Bitte wählen',
                    },
                  }}
                />
              </FormGroup>
              <FormGroup
                label="Sind in den vergangenen fünf Jahren Schäden (auch nicht versicherte Schäden) eingetreten?"
                error={this.showError(contractStep.forms.preDamages)}
              >
                <Control.radio
                  disabled={contractStep.locked}
                  model=".preDamages"
                  value={contractStep.preDamages}
                  name="preDamages"
                  component={Radio}
                  errors={validators.preDamages}
                  controlProps={{
                    options: [
                      { label: 'Ja', value: 'ja' },
                      { label: 'Nein', value: 'nein' },
                    ],
                    messages: {
                      required: 'Bitte wählen',
                    },
                  }}
                />
              </FormGroup>
              {business && (
                <div>
                  <FormGroup label="Baujahr" htmlFor="droneBuildYear" error={this.showError(contractStep.forms.droneBuildYear)}>
                    <Select
                      disabled={contractStep.locked}
                      model=".droneBuildYear"
                      name="droneBuildYear"
                      validators={validators.droneBuildYear}
                      messages={{
                        required: 'Bitte das Baujahr des Mulitcopters auswählen',
                      }}
                      children={baujahrChildren}
                    />
                  </FormGroup>
                  <FormGroup error={this.showError(contractStep.forms.droneManufacturer)}>
                    <Text
                      label="Hersteller*"
                      model="dhvForm.contractStep.droneManufacturer"
                      disabled={contractStep.locked}
                      name="droneManufacturer"
                      validators={validators.droneManufacturer}
                      messages={{
                        required: 'Bitte geben Sie den Hersteller des Multicopters an.',
                        max: 'Der Hersteller darf maximal 29 Zeichen enthalten.',
                      }}
                    />
                  </FormGroup>
                  <FormGroup error={this.showError(contractStep.forms.droneType)}>
                    <Text
                      label="Typ (Verkaufsbezeichnung)*"
                      model="dhvForm.contractStep.droneType"
                      disabled={contractStep.locked}
                      name="droneType"
                      validators={validators.droneType}
                      messages={{
                        required: 'Bitte geben Sie die Verkaufsbezeichnung des Multicopters an.',
                        max: 'Die Verkaufsbezeichnung darf maximal 29 Zeichen enthalten.',
                      }}
                    />
                  </FormGroup>
                  <FormGroup error={this.showError(contractStep.forms.droneNumber)}>
                    <Text
                      label="Seriennummer*"
                      model="dhvForm.contractStep.droneNumber"
                      disabled={contractStep.locked}
                      name="droneNumber"
                      validators={validators.droneNumber}
                      messages={{
                        required: 'Bitte geben Sie die Seriennummer des Multicopters an.',
                        max: 'Die Seriennummer darf maximal 24 Zeichen enthalten.',
                      }}
                    />
                  </FormGroup>
                </div>
              )}
              <p className="sub-note">mit * gekennzeichnete Felder sind Pflichtfelder</p>
            </FieldSetContent>
          </FieldSet>
          <FieldSet>
            <button
              disabled={contractStep.locked}
              className="btn btn--primary btn-next float-right"
              type="submit"
            >
              <span className="icon i-cta" />
              Weiter
            </button>
          </FieldSet>
        </Form>
      </div>
    )
  }
}

const wrapped = withRouter<ContractStepProps>(ContractStep)
export { wrapped as ContractStep }
