import { Schema } from 'common/dots-schema'
import iban from 'iban'
import { PaymentType } from 'client/dhv-form-payment/api/client/state/payment-step.state'

export const DirectDebitSchemaModel = (paymentType: string) => {
  return {
    brand: {
      type: String,
      optional: true,
    },
    iban: {
      type: String,
      optional: true,
      custom: (value: string) => {
        if (paymentType !== PaymentType.Lastschrift) return null
        return iban.isValid(value) ? null : 'iban is invalid'
      },
    },
    bic: {
      type: String,
      optional: true,
    },
    sameOwner: {
      type: String,
      custom: (value: string) => {
        if (paymentType === PaymentType.Rechnung && value === 'false') return 'sameOwner is invalid'
        return null
      },
    },
    /*acceptedDirectDebit: {
      type: Boolean,
      custom: (value: boolean) => {
        if (paymentType === 'Rechnung') return null
        return value ? null : 'acceptedDirectDebit is invalid'
      },
    },*/
  }
}

export const DirectDebitSchema = (paymentType: string) => new Schema(DirectDebitSchemaModel(paymentType))
