// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  position: fixed;
  height: 66px;
  padding: 0 22px;
  top: 0;
  width: 100%;
  z-index: 1000;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: height 0.25s linear;
  transition: height 0.25s linear;
  background-color: #fff;
  display: grid !important;
}

header.is-sticky {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 -3px 15px #003;
  border-collapse: separate;
}

.img-container {
  align-self: center;
}

img {
  height: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/client/dhv-common/ui/client/components/header.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,MAAM;EACN,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,sBAAsB;EACtB,8BAA8B;EAC9B,qBAAqB;EACrB,iBAAiB;EACjB,SAAS;EACT,uCAAuC;EACvC,+BAA+B;EAC/B,uCAAuC;EACvC,+BAA+B;EAC/B,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":["header {\n  position: fixed;\n  height: 66px;\n  padding: 0 22px;\n  top: 0;\n  width: 100%;\n  z-index: 1000;\n  -webkit-box-align: center;\n  -ms-flex-align: center;\n  align-items: center;\n  -webkit-box-pack: justify;\n  -ms-flex-pack: justify;\n  justify-content: space-between;\n  -ms-flex-wrap: nowrap;\n  flex-wrap: nowrap;\n  margin: 0;\n  -webkit-transform: translate3d(0, 0, 0);\n  transform: translate3d(0, 0, 0);\n  -webkit-transition: height 0.25s linear;\n  transition: height 0.25s linear;\n  background-color: #fff;\n  display: grid !important;\n}\n\nheader.is-sticky {\n  height: 60px;\n  background-color: #fff;\n  box-shadow: 0 -3px 15px #003;\n  border-collapse: separate;\n}\n\n.img-container {\n  align-self: center;\n}\n\nimg {\n  height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
