import moment from 'moment'
import { Schema } from 'common/dots-schema'
import { Person } from 'common/dhv-person'
import { PersonStepState } from 'client/dhv-form-person'
import { mapPersonStepToOwner } from './../../../../dhv-form/api/client/actions/finish.action'

export const PersonBaseSchemaModel = (owner: Person, differingPayer: boolean) => {
  return {
    foa: {
      type: String,
      max: 5,
    },
    title: {
      type: String,
      max: 70,
      optional: true,
    },
    name: {
      type: String,
      max: 70,
    },
    firstname: {
      type: String,
      max: 70,
      regEx: /^[^()[\]{}*&^%$#@!0-9]*$/,
      optional: owner.foa === 'Firma',
    },
    birthdate: {
      type: Date,
      custom: (value: Date) => {
        if (value instanceof Date && moment(value).add(moment.duration(18, 'years')).isAfter(moment())) {
          return 'age must be at leat 18'
        }
        return null
      },
      optional: owner.foa === 'Firma' || differingPayer,
    },
    street: {
      type: String,
      max: 70,
    },
    postalCode: {
      type: String,
      max: 5,
      min: 5,
    },
    city: {
      type: String,
      max: 60,
    },
    phone: {
      type: String,
      max: 20,
      optional: true,
      regEx: /^(:?\+|0)[\/\d\s()]*$/,
    },
    email: {
      type: String,
      max: 70,
      regEx: Schema.RegEx.Email,
      optional: differingPayer ? true : false,
    },
  }
}

export const PersonStepSchemaModel = (_personStep: PersonStepState, differingPayer: boolean) => {
  return {
    ...PersonBaseSchemaModel(mapPersonStepToOwner(_personStep), differingPayer),
    vsab: {
      type: String,
      optional: differingPayer ? true : false,
    },
    vsabValue: {
      type: String,
      optional: true,
      custom: (value: Date, personStep: any) => {
        if (!value && personStep.vsab === 'Ja') {
          return 'required'
        }
        return null
      },
    },
  }
}

export const PersonStepSchema = (personStep: PersonStepState) => new Schema(PersonStepSchemaModel(personStep, false))
