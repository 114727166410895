import React from 'react'
import { Tooltip } from 'react-bootstrap'

interface Props {
  message: string
  tsx?: any
  isOverlayClosed: boolean
  positionTop?: number
}

export class OverlayTooltip extends React.Component<Props, {}> {
  render() {
    return (
      <div>
        {!this.props.isOverlayClosed && (
          <Tooltip
            id={`title-tooltip-${Math.random().toString()}`}
            className="infobox-msg"
            positionTop={this.props.positionTop || 60}
          >
            {this.props.tsx ? this.props.tsx : this.props.message}
          </Tooltip>
        )}
      </div>
    )
  }
}
