import { SET_BROKERID } from 'client/dhv-form/api/client/actions/set-brokerid.action'
import { dhvFormStateDefault } from 'dhv-common'

export function brokerId(state: string = dhvFormStateDefault.completeStep.brokerId || '', action: any): string {
  if (action.type === SET_BROKERID) {
    return action.brokerId
  } else {
    return state
  }
}
