import React from 'react'
import { FormGroup, Radio, Text } from 'zur-ui-forms'

interface Props {
  errors: any
  validators: any
}

export class NameFields extends React.Component<Props, {}> {
  render() {
    const { errors, validators } = this.props
    return (
      <div>
        <FormGroup label="Anrede*" error={errors.foa}>
          <Radio
            options={[
              { label: 'Herr', value: 'Herr' },
              { label: 'Frau', value: 'Frau' },
            ]}
            name="foa"
            validators={validators.foa}
            messages={{
              required: 'Bitte eine Anrede wählen',
            }}
          />
        </FormGroup>
        <FormGroup label="Titel">
          <Text name="title" />
        </FormGroup>
        <FormGroup label="Vorname*" error={errors.firstname}>
          <Text
            name="firstname"
            validators={validators.firstname}
            messages={{
              required: 'Bitte einen Vornamen angeben',
              max: 'Der Vorname darf nicht länger als 70 Zeichen sein',
              regEx: 'Der Vorname darf nur die Zeichen a-z, A-Z, "." und "-" enthalten',
            }}
          />
        </FormGroup>
        <FormGroup label="Name*" error={errors.name}>
          <Text
            name="name"
            validators={validators.name}
            messages={{
              required: 'Bitte einen Namen angeben',
              max: 'Der Name darf nicht länger als 70 Zeichen sein',
              regEx: 'Der Name darf nur die Zeichen a-z, A-Z, "." und "-" enthalten',
            }}
          />
        </FormGroup>
      </div>
    )
  }
}
