import { isBefore, isAfter, isValid, parse } from 'date-fns'

export const removeTimeFromDate = (date: Date) => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  return d
}

export const fixDateInputString = (newValue: string, oldValue: string): string => {
  if (newValue.endsWith('.') && newValue.length !== 3 && newValue.length !== 6) {
    newValue = oldValue
  } else if ((newValue.length === 3 || newValue.length === 6) && !newValue.endsWith('.') && newValue.length > oldValue.length) {
    newValue = newValue.substring(0, newValue.length - 1) + '.' + newValue.substring(newValue.length - 1)
  } else if (newValue.length > 10) {
    newValue = newValue.substring(0, 10)
  }
  return newValue
}

export const isValidDate = (date: Date, minDate: Date | undefined, maxDate: Date | undefined) => {
  // Add hour, minute and seconds from maxDate to calendar date which is provided from datepicker without time
  let valid = true
  if (!isValid(date)) {
    valid = false
  }
  if (minDate) {
    minDate = removeTimeFromDate(minDate)
    if (isBefore(removeTimeFromDate(date), minDate)) {
      valid = false
    }
  }
  if (maxDate) {
    maxDate = removeTimeFromDate(maxDate)
    if (isAfter(removeTimeFromDate(date), maxDate)) {
      valid = false
    }
  }
  return valid
}

export const parseDateFromString = (value: string, minDate: Date | undefined, maxDate: Date | undefined): Date | null => {
  if (value.length !== 10) {
    return null
  }
  const date = parse(value, 'dd.MM.yyyy', new Date())
  if (isValidDate(date, minDate, maxDate)) {
    return date
  } else {
    return null
  }
}
