import axios, { AxiosResponse } from 'axios'
import { DroneInsuranceCalculateResult } from 'common/drone-insurance/drone-result'
import { DroneHaftpflicht } from 'drone-insurance/drone-haftpflicht'
import { DroneKasko } from 'drone-insurance/drone-kasko'

export async function calculateRequest(
  scope: string,
  business: boolean,
  haftpflicht: DroneHaftpflicht | null,
  kasko: DroneKasko | null
): Promise<AxiosResponse<DroneInsuranceCalculateResult> | undefined> {
  if (!haftpflicht && !kasko) {
    throw new Error('Provide either haftpflicht or kasko.')
  }

  const payload = {
    insurance: {
      business,
      scope,
      ...((scope === 'haftpflicht' || scope === 'haftpflichtKasko') && { haftpflicht }),
      ...((scope === 'kasko' || scope === 'haftpflichtKasko') && { kasko }),
    },
  }

  axios.defaults.withCredentials = true
  return axios.post(`${process.env.API_HOST || ''}/api/insuranceCalculate`, payload)
}
