// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control-feedback {
  top: 5px;
  right: 22px;
  width: 50px;
}

@media (max-width: 767px) {
  .aligned-icon {
    margin: 0 0 0 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/client/dhv-form-payment/ui/client/payment-step.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,WAAW;EACX,WAAW;AACb;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".form-control-feedback {\n  top: 5px;\n  right: 22px;\n  width: 50px;\n}\n\n@media (max-width: 767px) {\n  .aligned-icon {\n    margin: 0 0 0 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
