import { SET_BROKEREMAIL } from 'client/dhv-form/api/client/actions/set-brokeremail.action'
import { dhvFormStateDefault } from 'dhv-common'

export function brokerEmail(state: string = dhvFormStateDefault.completeStep.brokerEmail || '', action: any): string {
  if (action.type === SET_BROKEREMAIL) {
    return action.brokerEmail
  } else {
    return state
  }
}
