import { combineReducers } from 'redux'

import { rateStepReducer as rateStep } from 'dhv-form-rate'
import { contractStepReducer as contractStep } from 'dhv-form-contract'
import { personStepReducer as personStep } from 'dhv-form-person'
import { downloadStepReducer as downloadStep } from 'dhv-form-download'
import { paymentStepReducer as paymentStep } from 'dhv-form-payment'
import { completeStepReducer as completeStep } from 'dhv-form-complete'
import { thanksStepReducer as thanksStep } from 'dhv-form-thanks'

import { finish } from './reducers/finish.reducer'
import { steps } from './reducers/steps.reducer'
import { DhvFormState } from 'dhv-form'

export const dhvFormReducer = combineReducers<DhvFormState>({
  isBusiness: (_ = true) => true,
  steps,
  rateStep,
  contractStep,
  personStep,
  paymentStep,
  downloadStep,
  completeStep,
  thanksStep,
  finish,
})
