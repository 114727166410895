import { Schema } from 'common/dots-schema'

export const PaymentStepSchemaModel = {
  paymentType: {
    type: String,
    custom: (value: string) => {
      return value === 'Lastschrift' || value === 'Rechnung' ? null : 'value paymentType must be Rechnung or Lastschrift'
    },
  },
}

export const PaymentStepSchema = new Schema(PaymentStepSchemaModel)
