// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locked-positioner {
  z-index: 100;
  position: fixed;
  width: 100%;
  left: 0%;
  top: 100px;
}

@media (min-width: 767px) {
  .locked-positioner {
    top: 250px;
  }
}

.locked-container {
  margin: 0 auto;
  width: 85%;
  padding: 15px;
  color: #fff;
  background-color: #4066b3;
  display: flex;
}

@media (max-width: 379px) {
  .locked-container {
    width: 100%;
  }
}

.locked-title {
  font-weight: bold;
}

.locked-button {
  display: block;
  margin: 10px;
  border: 1px solid #fff;
  padding: 1px;
  height: 86px;
}

.locked-buttonContent {
  padding: 2px;
  color: #4066b3;
  height: 82px;
  line-height: 80px;
  background-color: #fff;
  vertical-align: middle;
}

.locked-buttonText {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  text-align: center;
}

.locked__close-icon {
  display: inline-block;
  position: relative;
  top: -5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/client/dhv-form-contract/ui/client/components/locked.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,WAAW;EACX,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,cAAc;EACd,UAAU;EACV,aAAa;EACb,WAAW;EACX,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,SAAS;EACT,eAAe;AACjB","sourcesContent":[".locked-positioner {\n  z-index: 100;\n  position: fixed;\n  width: 100%;\n  left: 0%;\n  top: 100px;\n}\n\n@media (min-width: 767px) {\n  .locked-positioner {\n    top: 250px;\n  }\n}\n\n.locked-container {\n  margin: 0 auto;\n  width: 85%;\n  padding: 15px;\n  color: #fff;\n  background-color: #4066b3;\n  display: flex;\n}\n\n@media (max-width: 379px) {\n  .locked-container {\n    width: 100%;\n  }\n}\n\n.locked-title {\n  font-weight: bold;\n}\n\n.locked-button {\n  display: block;\n  margin: 10px;\n  border: 1px solid #fff;\n  padding: 1px;\n  height: 86px;\n}\n\n.locked-buttonContent {\n  padding: 2px;\n  color: #4066b3;\n  height: 82px;\n  line-height: 80px;\n  background-color: #fff;\n  vertical-align: middle;\n}\n\n.locked-buttonText {\n  display: inline-block;\n  vertical-align: middle;\n  line-height: normal;\n  text-align: center;\n}\n\n.locked__close-icon {\n  display: inline-block;\n  position: relative;\n  top: -5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
