import update from 'immutability-helper'

import { dhvFormStateDefault } from 'dhv-common'
import { FINISH_FORM_SUCCESS, FINISH_FORM_FAILED, START_FINISH_FORM, RESET_FINISH_FORM } from '../actions/finish.action'
import { FinishState } from '../dhv-form.state'

const serverError = `Es tut uns Leid, aber die von Ihnen eingegeben Daten konnten nicht erfolgreich verarbeitet werden.
Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal. Sollte das Problem bestehen bleiben wenden Sie sich gerne direkt an uns per E-Mail an luftfahrt@zurich.com`

export function finish(state: FinishState = dhvFormStateDefault.finish, action: any): FinishState {
  switch (action.type) {
    case FINISH_FORM_SUCCESS:
      if (action.payload && action.payload.data) {
        return update(state, {
          $set: {
            isLoading: false,
            status: action.payload.data.status,
            error:
              action.payload.data.status === 200
                ? null
                : action.payload.data.error === '400-payment-error'
                ? `Leider konnten die von Ihnen eingegeben Kreditkarten-Informationen nicht erfolgreich verarbeitet werden.
                      Bitte prüfen Sie ihre Eingabe oder verwenden Sie eine alternative Zahlungsweise.`
                : serverError,
          },
        })
      } else {
        return update(state, {
          $set: {
            isLoading: false,
            status: 500,
            error: serverError,
          },
        })
      }
    case FINISH_FORM_FAILED:
      return update(state, {
        $set: {
          isLoading: false,
          status: 500,
          error: serverError,
        },
      })
    case START_FINISH_FORM:
      return update(state, {
        $set: {
          isLoading: true,
          status: 0,
          error: null,
        },
      })
    case RESET_FINISH_FORM:
      return update(state, {
        $set: {
          isLoading: false,
          status: state.status,
          error: null,
        },
      })
    default:
      return state
  }
}
