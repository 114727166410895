export class Person {
  foa?: string

  title?: string

  name: string

  vsab?: boolean

  vsabValue?: number

  firstname?: string

  birthdate?: Date | null

  street?: string

  postalCode?: string

  nationality?: string

  city?: string

  phone?: string

  email?: string

  emailConfirmation?: string

  postalDelivery?: boolean
}

export function getFullName(person: Person, includeFirstname = true, includeFoa = true) {
  let result
  if (includeFirstname) {
    result = person.title
      ? `${includeFoa ? person.foa : ''} ${person.title} ${person.firstname} ${person.name}`
      : `${includeFoa ? person.foa : ''} ${person.firstname} ${person.name}`
  } else {
    result = person.title
      ? `${includeFoa ? person.foa : ''} ${person.title} ${person.name}`
      : `${includeFoa ? person.foa : ''} ${person.name}`
  }
  return result.trim()
}
