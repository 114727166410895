import { dhvFormStateDefault } from 'dhv-common'
import { CLICKED_DOWNLOAD } from '../actions/clicked-download.action'

export function clickedDownload(state: boolean = dhvFormStateDefault.downloadStep.clickedDownload, action: any): boolean {
  if (action.type === CLICKED_DOWNLOAD) {
    return action.clicked
  } else {
    return state
  }
}
