import { ActionCreator } from 'redux'

export interface SetBrokerEmailAction {
  type: 'SET_BROKEREMAIL'
  brokerEmail: string
}
export const SET_BROKEREMAIL = 'SET_BROKEREMAIL'
export const setBrokerEmail: ActionCreator<SetBrokerEmailAction> = (brokerEmail: string) => {
  return {
    type: SET_BROKEREMAIL,
    brokerEmail: brokerEmail,
  }
}
