import React from 'react'

import { Header, Footer } from 'client/dhv-common'

interface Props {
  location?: {
    query?: {
      hideHeaderFooter?: boolean
    }
  }
}

export class App extends React.Component<Props, {}> {
  hide?: boolean

  constructor(props: Props) {
    super(props)
    if (props.location) {
      this.hide = props.location.query && props.location.query.hideHeaderFooter
    }
  }

  render() {
    return (
      <div className="wrapper">
        <div className="container-wrapper">
          {!this.hide && <Header />}

          <div className="container">{this.props.children}</div>
          <div className="push" />
        </div>
        {!this.hide && <Footer />}
      </div>
    )
  }
}
