import { PaymentType } from 'client/dhv-form-payment/api/client/state/payment-step.state'
import axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { ActionCreator } from 'redux'

import { DhvFormState } from 'dhv-form'
import { DroneInsuranceCheckout, DroneInsuranceCheckoutWrapper } from 'common/drone-insurance'
import { DroneInsuranceCheckoutResult } from 'common/drone-insurance/drone-result'
import { PersonStepState } from 'client/dhv-form-person'
import { Person } from 'common/dhv-person'

export const FINISH_FORM_SUCCESS = 'FINISH_FORM_SUCCESS'
export const FINISH_FORM_FAILED = 'FINISH_FORM_FAILED'
export const START_FINISH_FORM = 'START_FINISH_FORM'
export const RESET_FINISH_FORM = 'RESET_FINISH_FORM'

export function calcEnddate(startDate: Date) {
  return moment(startDate).add(1, 'year').toDate()
}

export function fixDate(date: Date | null | undefined) {
  if (!date) {
    return date
  }
  return moment(date).hour(11).toDate()
}

export const mapPersonStepToOwner = (personStep: PersonStepState): Person => {
  return {
    foa: personStep.foa,
    title: personStep.title,
    name: personStep.name,
    firstname: personStep.firstname,
    birthdate: fixDate(personStep.birthdate),
    street: personStep.street,
    postalCode: personStep.postalCode,
    city: personStep.city,
    phone: personStep.phone,
    email: personStep.email,
    emailConfirmation: personStep.emailConfirmation,
    vsab: personStep.vsab === 'Ja',
    vsabValue: personStep.vsab === 'Ja' ? parseInt(personStep.vsabValue as string, 10) : undefined,
    postalDelivery: personStep.postalDelivery,
  }
}

export const mapInsuranceValues = (form: DhvFormState): DroneInsuranceCheckout => {
  return {
    business: form.rateStep.usage === 'g',
    scope: form.rateStep.scope,
    startDate: fixDate(form.contractStep.startDate) as Date,
    endDate: fixDate(calcEnddate(form.contractStep.startDate as Date)) as Date,
    preDamages: form.contractStep.preDamages === 'ja',
    preExistingContracts: form.contractStep.preExistingContracts === 'ja',
    droneBuildYear: form.rateStep.usage === 'g' ? form.contractStep.droneBuildYear : '-',
    droneManufacturer: form.rateStep.usage === 'g' ? form.contractStep.droneManufacturer : 'diverse',
    droneNumber: form.rateStep.usage === 'g' ? form.contractStep.droneNumber : '-',
    droneType: form.rateStep.usage === 'g' ? form.contractStep.droneType : 'Multicopter & Flugmodelle',
    kasko:
      form.rateStep.scope === 'kasko' || form.rateStep.scope === 'haftpflichtKasko'
        ? {
            dronePrice: form.rateStep.kasko.dronePrice,
            droneLending: form.rateStep.kasko.droneLending,
            priceBrutto: form.rateStep.kasko.priceBrutto,
            priceNetto: form.rateStep.kasko.priceNetto,
            priceTax: form.rateStep.kasko.priceTax,
          }
        : undefined,
    haftpflicht:
      form.rateStep.scope === 'haftpflicht' || form.rateStep.scope === 'haftpflichtKasko'
        ? {
            coverage: form.rateStep.haftpflicht.coverage,
            insuranceSum: form.rateStep.haftpflicht.insuranceSum,
            deductible: form.rateStep.haftpflicht.deductible,
            priceBrutto: form.rateStep.haftpflicht.priceBrutto,
            priceNetto: form.rateStep.haftpflicht.priceNetto,
            priceTax: form.rateStep.haftpflicht.priceTax,
          }
        : undefined,
    owner: mapPersonStepToOwner(form.personStep),
    payment: {
      paymentType: form.paymentStep.paymentType,
      iban: form.paymentStep.paymentType === PaymentType.Lastschrift ? form.paymentStep.directDebit.iban : '',
      bic: form.paymentStep.paymentType === PaymentType.Lastschrift ? form.paymentStep.directDebit.bic : '',
      sameOwner:
        form.paymentStep.paymentType === PaymentType.Lastschrift ? form.paymentStep.directDebit.sameOwner === 'ja' : true,
    },
    differingPayer:
      form.paymentStep.paymentType === PaymentType.Lastschrift && form.paymentStep.directDebit.sameOwner === 'nein'
        ? {
            foa: form.paymentStep.directDebit.owner.foa,
            title: form.paymentStep.directDebit.owner.foa !== 'Firma' ? form.paymentStep.directDebit.owner.title : '',
            name: form.paymentStep.directDebit.owner.name,
            firstname: form.paymentStep.directDebit.owner.foa !== 'Firma' ? form.paymentStep.directDebit.owner.firstname : '',
            street: form.paymentStep.directDebit.owner.street,
            postalCode: form.paymentStep.directDebit.owner.postalCode,
            city: form.paymentStep.directDebit.owner.city,
            email: form.paymentStep.directDebit.owner.email,
            phone: form.paymentStep.directDebit.owner.phone,
          }
        : undefined,
  }
}

export const mapFormValues = (form: DhvFormState): DroneInsuranceCheckoutWrapper => {
  return {
    insurance: mapInsuranceValues(form),
    brokerId: form.completeStep.brokerId || '',
    brokerEmail: form.completeStep.brokerEmail || '',
    brokerEmail2: form.completeStep.brokerEmail2 || '',
    milesAndMore: form.completeStep.milesAndMore || '',
    acceptedContact: form.completeStep.acceptedContact,
    acceptedEmail: form.completeStep.acceptedContact && form.completeStep.acceptedEmail,
    acceptedPhone: form.completeStep.acceptedContact && form.completeStep.acceptedPhone,
    acceptedMobile: form.completeStep.acceptedContact && form.completeStep.acceptedMobile,
    acceptedSms: form.completeStep.acceptedContact && form.completeStep.acceptedSms,
  }
}

export const finishRequest = async (form: DhvFormState): Promise<AxiosResponse<DroneInsuranceCheckoutResult> | undefined> => {
  axios.defaults.withCredentials = true
  return axios.post(`${process.env.API_HOST || ''}/api/insuranceCheckout`, mapFormValues(form))
}

export const dispatchFinish = (form: DhvFormState) => {
  return async (dispatch: any) => {
    const result = await finishRequest(form)
    if (result && result.data && result.data.status === 200) {
      return dispatch({
        type: FINISH_FORM_SUCCESS,
        payload: result,
      })
    } else {
      return dispatch({
        type: FINISH_FORM_FAILED,
      })
    }
  }
}

export interface StartFinishAction {
  type: 'START_FINISH_FORM'
}

export const startFinish: ActionCreator<StartFinishAction> = () => {
  return {
    type: START_FINISH_FORM,
  }
}

export interface ResetFinishAction {
  type: 'RESET_FINISH_FORM'
}

export const resetFinish: ActionCreator<ResetFinishAction> = () => {
  return {
    type: RESET_FINISH_FORM,
  }
}
