// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nested-fieldset {
  margin: 0;
}

.calcButtonContainer {
  margin: 20px 0;
}

@media (max-width: 767px) {
  .cta.cta-3 .icon {
    width: 20px;
    font-size: 20px;
  }
}

.has-error.calculate-error {
  font-size: 20px;
}

.calc-container {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/client/dhv-form-rate/ui/client/rate-step.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".nested-fieldset {\n  margin: 0;\n}\n\n.calcButtonContainer {\n  margin: 20px 0;\n}\n\n@media (max-width: 767px) {\n  .cta.cta-3 .icon {\n    width: 20px;\n    font-size: 20px;\n  }\n}\n\n.has-error.calculate-error {\n  font-size: 20px;\n}\n\n.calc-container {\n  margin-top: 3rem;\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
