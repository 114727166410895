import { ActionCreator } from 'redux'

export interface SetDisableBrokerFieldsAction {
  type: 'SET_DISABLEBROKERFIELDS'
}
export const SET_DISABLEBROKERFIELDS = 'SET_DISABLEBROKERFIELDS'
export const setDisableBrokerFields: ActionCreator<SetDisableBrokerFieldsAction> = () => {
  return {
    type: SET_DISABLEBROKERFIELDS,
  }
}
