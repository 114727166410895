import { DirectDebit } from 'zur-payments'

export enum PaymentType {
  Lastschrift = 'Lastschrift',
  Rechnung = 'Rechnung',
}

export interface PaymentStep {
  paymentType: PaymentType

  directDebit: DirectDebit

  acceptedDirectDebit: boolean

  sameOwner: string

  forms: any
}
