import { ActionCreator } from 'redux'

export interface SetBrokerIdAction {
  type: 'SET_BROKERID'
  brokerId: string
}
export const SET_BROKERID = 'SET_BROKERID'
export const setBrokerId: ActionCreator<SetBrokerIdAction> = (brokerId: string) => {
  return {
    type: SET_BROKERID,
    brokerId: brokerId,
  }
}
