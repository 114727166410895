import { DhvFormState } from 'dhv-form'
import { PaymentType } from 'client/dhv-form-payment/api/client/state/payment-step.state'

const emptyDefault: DhvFormState = {
  isBusiness: false,
  steps: {
    active: 0,
    current: 0,
    locked: false,
  },
  finish: {
    isLoading: false,
    error: null,
    status: 0,
  },
  rateStep: {
    forms: undefined,
    usage: 'p',
    scope: 'haftpflicht',
    isCalculating: false,
    errorMessage: '',
    kasko: {
      forms: undefined,
      dronePrice: '',
      droneLending: false,
      priceBrutto: 0,
      priceTax: 0,
      priceNetto: 0,
    },
    haftpflicht: {
      forms: undefined,
      coverage: 'world',
      insuranceSum: '3000000',
      deductible: '0',
      priceBrutto: 0,
      priceTax: 0,
      priceNetto: 0,
    },
  },
  contractStep: {
    locked: false,
    startDate: null,
    preExistingContracts: '',
    preDamages: '',
    droneBuildYear: '',
    droneManufacturer: '',
    droneType: '',
    droneNumber: '',
    forms: undefined,
  },
  personStep: {
    foa: '',
    title: '',
    name: '',
    firstname: '',
    birthdate: null,
    street: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    phone: '',
    email: '',
    emailConfirmation: '',
    vsab: '',
    vsabValue: '',
    postalDelivery: false,
    forms: undefined,
  },
  paymentStep: {
    directDebit: {
      iban: '',
      bic: '',
      owner: {
        foa: '',
        title: '',
        name: '',
        firstname: '',
        street: '',
        postalCode: '',
        city: '',
        phone: '',
        email: '',
        forms: undefined,
      },
      sameOwner: '',
      forms: undefined,
    },
    paymentType: PaymentType.Lastschrift,
    acceptedDirectDebit: false,
    sameOwner: '',
    forms: undefined,
  },
  downloadStep: {
    clickedDownload: false,
    readAndAccepted: false,
    received: false,
  },
  completeStep: {
    acceptedContact: false,
    acceptedPhone: false,
    acceptedEmail: false,
    acceptedMobile: false,
    acceptedSms: false,
    brokerId: '',
    brokerEmail: '',
    brokerEmail2: '',
    milesAndMore: '',
    forms: undefined,
  },
  thanksStep: {
    landingPage: 'https://www.zurich.de',
    vsnrHaftpflicht: '',
    vsnrKasko: '',
  },
}

export const filledDefault: DhvFormState = {
  isBusiness: true,
  steps: {
    active: 0,
    current: 0,
    locked: false,
  },
  finish: {
    isLoading: false,
    status: 0,
    error: null,
  },
  rateStep: {
    forms: undefined,
    usage: 'g',
    scope: 'haftpflichtKasko',
    isCalculating: false,
    errorMessage: '',
    kasko: {
      forms: undefined,
      dronePrice: '1000',
      droneLending: true,
      priceBrutto: 214.2,
      priceTax: 34.2,
      priceNetto: 180,
    },
    haftpflicht: {
      forms: undefined,
      coverage: 'eu',
      insuranceSum: '3000000',
      deductible: '0',
      priceBrutto: 142.8,
      priceTax: 22.8,
      priceNetto: 120,
    },
  },
  contractStep: {
    locked: false,
    startDate: new Date(),
    preExistingContracts: 'nein',
    preDamages: 'nein',
    droneBuildYear: '2022',
    droneManufacturer: 'Stuffs Gmbh',
    droneType: 'Sheep',
    droneNumber: '0012345A',
    forms: undefined,
  },
  personStep: {
    foa: 'Herr',
    title: '',
    name: 'Mustermann',
    firstname: 'Hä',
    birthdate: new Date(2000, 1, 1),
    street: 'Str 5a',
    streetNumber: '5a',
    postalCode: '12345',
    city: 'Stadt',
    phone: '0123 / 123123',
    email: 'test@test.de',
    emailConfirmation: 'test@test.de',
    vsab: 'Ja',
    vsabValue: '19',
    postalDelivery: true,
    forms: undefined,
  },
  paymentStep: {
    directDebit: {
      iban: 'DE12500105170648489890',
      bic: 'PBNKDEFF',
      owner: {
        foa: 'Herr',
        title: '',
        name: 'Test',
        firstname: 'Jan',
        street: 'Str',
        postalCode: '12345',
        city: 'City',
        phone: '012345',
        email: 'test@test.de',
        forms: undefined,
      },
      sameOwner: 'nein',
      forms: undefined,
    },
    paymentType: PaymentType.Lastschrift,
    acceptedDirectDebit: false,
    sameOwner: '',
    forms: undefined,
  },
  downloadStep: {
    clickedDownload: false,
    readAndAccepted: false,
    received: false,
  },
  completeStep: {
    acceptedContact: false,
    acceptedPhone: false,
    acceptedEmail: false,
    acceptedMobile: false,
    acceptedSms: false,
    brokerId: '216040882',
    brokerEmail: 'malte.anthes@zurich.com',
    brokerEmail2: '',
    /* brokerId: '216066783',
    brokerEmail: 'mm@zurich.com',
    brokerEmail2: '', */
    /* brokerId: '496028803',
    brokerEmail: 'broker@broker.de',
    brokerEmail2: 'broker2@broker.de', */
    milesAndMore: '',
    //milesAndMore: '123456789012345',
    forms: undefined,
  },
  thanksStep: {
    landingPage: 'https://www.zurich.de',
    vsnrHaftpflicht: '',
    vsnrKasko: '',
  },
}

export { emptyDefault as dhvFormStateDefault }
