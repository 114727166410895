import moment from 'moment'
import { Schema } from 'common/dots-schema'

export const ContractStepSchemaModelNonBusiness = {
  startDate: {
    type: Date,
    custom: (value: Date) => {
      if (
        value instanceof Date &&
        moment(value).isBefore(moment()) &&
        moment(value).isAfter(moment().add(moment.duration(1, 'years')))
      ) {
        return 'Bitte den Versicherungsbeginn angeben (maximal 1 Jahr in der Zukunft)'
      }
      return null
    },
  },
  preExistingContracts: {
    type: String,
  },
  preDamages: {
    type: String,
  },
}

export const ContractStepSchemaModel = {
  ...ContractStepSchemaModelNonBusiness,
  droneBuildYear: {
    type: Number,
  },
  droneManufacturer: {
    type: String,
    max: 29,
  },
  droneType: {
    type: String,
    max: 29,
  },
  droneNumber: {
    type: String,
    max: 24,
  },
}

export const ContractStepSchema = new Schema(ContractStepSchemaModel)
export const ContractStepSchemaNonBusiness = new Schema(ContractStepSchemaModelNonBusiness)
