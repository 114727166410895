import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { dispatchFinish, startFinish, resetFinish, setStep } from 'dhv-form'
import { CompleteStep as CompleteStepComponent } from '../../ui/client/complete-step.component'
import { AppState } from 'dhv-common'

const mapStateToProps = (state: AppState) => ({
  dhvForm: state.dhvForm,
  completeStep: state.dhvForm.completeStep,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  const boundActionCreators = bindActionCreators(
    {
      setStep,
      dispatchFinish,
      startFinish: startFinish,
      resetFinish: resetFinish,
    },
    dispatch
  )

  return {
    ...boundActionCreators,
  }
}

export const CompleteStep = connect(mapStateToProps, mapDispatchToProps)(CompleteStepComponent)
