// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) {
  .footer-legal {
    display: block !important;
  }
}

.footer-social {
  max-width: 350px;
  margin-bottom: 3rem;
}

.social-bar ul li .icon:before {
  font-size: 34px !important;
}

.footer h3 {
  font-family: 'ZurichSans-SemiBold', Arial, sans-serif;
  color: #fff;
  font-size: 18px;
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/client/dhv-common/ui/client/components/footer.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qDAAqD;EACrD,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB","sourcesContent":["@media (max-width: 767px) {\n  .footer-legal {\n    display: block !important;\n  }\n}\n\n.footer-social {\n  max-width: 350px;\n  margin-bottom: 3rem;\n}\n\n.social-bar ul li .icon:before {\n  font-size: 34px !important;\n}\n\n.footer h3 {\n  font-family: 'ZurichSans-SemiBold', Arial, sans-serif;\n  color: #fff;\n  font-size: 18px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
