import React from 'react'
import MobileDetect from 'mobile-detect'
import { OverlayTooltip } from 'client/zur-ui-forms/ui/overlay-wrapper/overlay-tooltip.component'

interface Props {
  message: string
  tsx?: any
  positionTop?: number
}

export class OverlayWrapper extends React.Component<Props, { isOverlayClosed: boolean }> {
  isMobile: boolean

  constructor(props: Props) {
    super(props)

    const mobileString = new MobileDetect(window.navigator.userAgent).mobile()
    this.isMobile = mobileString ? true : false

    this.state = { isOverlayClosed: true }
  }

  closeOverlay() {
    this.setState({ isOverlayClosed: true })
  }

  render() {
    const { message, tsx, positionTop } = this.props
    return (
      <>
        <span
          data-testid="overlay-wrapper-icon"
          className="icon icon--info_24_outline overlay--marginLeft"
          onMouseEnter={(event) => {
            if (!this.isMobile) {
              event.stopPropagation()
              this.setState({ isOverlayClosed: !this.state.isOverlayClosed })
            }
          }}
          onMouseLeave={(event) => {
            if (!this.isMobile) {
              event.stopPropagation()
              this.setState({ isOverlayClosed: !this.state.isOverlayClosed })
            }
          }}
          onClick={(event) => {
            event.stopPropagation()
            if (this.isMobile) {
              this.setState({ isOverlayClosed: !this.state.isOverlayClosed })
            }
          }}
        />
        <OverlayTooltip message={message} tsx={tsx} isOverlayClosed={this.state.isOverlayClosed} positionTop={positionTop} />
      </>
    )
  }
}
